import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/layout";
import BreadCrumbs from "../components/common/breadcrumbs";
import InformationTitle from "../components/information/information-title";
import InformationBody from "../components/information/information-body";

export const query = graphql`
  query AnnouncementTemplateQuery($id: String!) {
    announcement: sanityAnnouncement(id: { eq: $id }) {
      id
      publishedAt(locale: "id", formatString: "dddd, DD MMM YYYY")
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        asset {
          id
        }
        alt
        caption
      }
    }
  }
`;

const TemplateAnnouncement = (props) => {
  const { data } = props;
  const announcement = data && data.announcement;

  return (
    <Layout>
      <BreadCrumbs
        parentPath="/berita"
        parentName="informasi"
        lastPath="/pengumuman"
        lastName="pengumuman"
        currentPage={announcement.title}
      />
      <InformationTitle
        title={announcement.title}
        publishedAt={announcement.publishedAt}
      />
      <InformationBody
        mainImage={announcement.mainImage}
        body={announcement._rawBody}
      />
    </Layout>
  );
};

export default TemplateAnnouncement;
